import InvoiceStatus from "src/components/invoices/InvoiceStatus";
import API from "src/utils/api/API";
import { InvoiceItem } from "src/utils/api/Invoices";

export default class Accounting {

    static async getAccountableSellers(): Promise<AccountableSellersSeller[]> {
        const resp = await API.get(`/v2/accounting`);
        if (!resp.success as any) throw new Error(resp.message);
        return resp.sellers as AccountableSellersSeller[];
    }

    static async getAccountableAuctions(args?: GetAccountableAuctionsArgs): Promise<AccountableAuctionsAuction[]> {

        let query: [string, string][] = [];
        if (args?.hasAccountable) query.push(['hasAccountable', 'true']);
        if (args?.limit) query.push(['limit', args.limit.toString()]);

        const resp = await API.get(`/v2/accounting/auctions${query.length > 0 ? '?' + new URLSearchParams(query).toString() : ''}`);
        if (!resp.success as any) throw new Error(resp.message);
        return resp.auctions as AccountableAuctionsAuction[];
    }

    static async getAccountableAuction(id: number): Promise<AccountableAuction> {
        const resp = await API.get(`/v2/accounting/auctions/${id}`);
        if (!resp.success as any) throw new Error(resp.message);
        return resp.auction as AccountableAuction;
    }

    static async discloseItems(auction_id: number, commissionPercentage: number, item_ids: number[]): Promise<void> {
        const resp = await API.post(`/v2/accounting/auctions/${auction_id}`, {
            commissionPercentage,
            invoiceItemIds: item_ids
        });
        if (!resp.success as any) throw new Error(resp.message);
    }

    static async getAccountingEntries(): Promise<AccountingEntry[]> {
        const resp = await API.get(`/v2/accounting/entries`);
        if (!resp.success as any) throw new Error(resp.message);
        return resp.entries as AccountingEntry[];
    }

    static async getAccountingEntryInternalSummary(id: number): Promise<string> {
        const resp = await API.get(`/v2/accounting/entries/${id}/internal-summary`);
        if (!resp.success as any) throw new Error(resp.message);
        return API.getURL() + resp.path as string;
    }

    static async getAccountSummary(): Promise<AccountEvent[]> {
        const resp = await API.get(`/v2/accounting/client-account-summary`);
        if (!resp.success as any) throw new Error(resp.message);
        return resp.summary as AccountEvent[];
    }

}

export type AccountEvent = {
    id: string;
    title: string;
    date: string;
    amount: number;
    index: number;
    sum: number;
}


export type AccountableSellersSeller = {
    id: number;
    name: string;
    trustee_id: number;
    trustee_name: string;
    customer_id: number;
    customer_name: string;
    num_items: number;
    total_net_amount: number;
    total_vat_amount: number;
}

export type AccountableAuctionsAuction = {
    id: number;
    name: string;
    ending: string;
    num_objects: number;
    won_items: number;
    accountable_objects: number;
    total_net_amount: number;
    total_vat_amount: number;
}

export type GetAccountableAuctionsArgs = {
    hasAccountable?: boolean;
    limit?: number;
}

export type AccountableAuction = {
    id: number;
    name: string;
    ending: string;
    total_service_fee: number;
    service_fee_net: number;
    service_fee_vat: number;
    serviceFeeRows: {
        id: number;
        invoice_id: number;
        net_amount: number;
        vat_amount: number;
        invoice_status: typeof InvoiceStatus[keyof typeof InvoiceStatus];
    }[];
    items: AccountableAuctionItem[];
}

export type AccountableAuctionItem = InvoiceItem & {
    invoice_status: typeof InvoiceStatus[keyof typeof InvoiceStatus],
    invoice_paid_date: string | null;
    object: null | {
        position: number;
        category: number | null;
        ending: string;
        leading_user: number;
        leading_username: string;
        leading_bid: number;
        leading_time_placed: number;
        thumbnail_url: null | string;
    },
    seller: null | {
        name: string;
        org_number: string;
        vat_number: string;
        bank_account_number: string;
        notes: string;
        trustee: null | {
            id: number;
            name: string;
            address: string;
            zip: string;
            city: string;
            phone: string;
            mobile_phone: string;
            email: string;
            added: string;
            customer: number
        }
        slug: string;
    }
}

export type AccountingEntry = {
    id: number;
    created_at: string;
    admin_id: number;
    auction_id: number;
    invoice_item_ids: number[];
    journals: {
        [key: string]: any;
    }[];
    commission_percentage: number;
    user: {
        id: number;
        username: string;
        email: string;
    } | null;
    auction: {
        id: number;
        name: string;
    } | null;
}