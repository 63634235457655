import React, { useEffect, useRef, useState } from "react";
import Table from "src/components/Table";
import Card from "src/layout/Card";
import Page from "src/layout/Page";
import { Link } from 'react-router-dom';
import styled from "styled-components";
import { AccountableAuctionItem } from "src/utils/api/accounting/Accounting";
import { caluclateCommissionAmount, formatPrice } from "src/utils/Util";
import useAccountableAuction from "src/utils/api/accounting/hooks/useAccountableAuction";
import Checkbox from "src/elements/Checkbox";
import Button from "src/elements/Button";
import { InvoiceStatus } from "src/utils/api/Invoices";
import usePopup from "src/utils/hooks/usePopup";
import DiscloseItems from "src/pages/accounting/components/DiscloseItems";
import Icon from "src/elements/Icon";
import {useReactToPrint} from "react-to-print";

// @ts-ignore
import html2pdf from "html2pdf.js";

export default function PageAccountableAuction(props: any) {
	let id = props.match.params.id;

	const [open] = usePopup();

	const [commissionPercentage, setCommissionPercentage] = useState(10);
	const [selectedIDs, setSelectedIDs] = useState([] as number[])
	const [onlyDelivered, setOnlyDelivered] = useState(false)
	const [onlyPaid, setOnlyPaid] = useState(true)
	const [onlyNonAccounted, setOnlyNonAccounted] = useState(true)

	const {auction, loading, load} = useAccountableAuction(id);

	useEffect(() => {
		if (!auction) {
			return;
		}

		selectVisible();
	}, [auction])

	function filter(item: AccountableAuctionItem) {

		if (onlyDelivered && !item.delivered) {
			return false;
		}

		if (onlyPaid && item.invoice_status !== InvoiceStatus.Paid) {
			return false;
		}

		if (onlyNonAccounted && item.accounted) {
			return false;
		}

		return true;
	}

	function filterSelected(item: AccountableAuctionItem) {
		return selectedIDs.includes(item.id);
	}

	function selectVisible() {
		if (auction) {
			setSelectedIDs(auction.items.filter(filter).map(i => i.id))
		}
	}

	const printable = useRef(null);

	const handleSavePDF = useReactToPrint({
		content: () => printable.current,
		documentTitle: `Auktionsredovisning: ${auction?.name ?? ''} ${new Date().toLocaleDateString('sv-SE')}.pdf`,
		copyStyles: true,
		print: async (printIframe: HTMLIFrameElement) => {
			const document = printIframe.contentDocument;
			if (document) {
				const html = document.getElementsByTagName('html')[0];
				await html2pdf().from(html).save(`Auktionsredovisning: ${auction?.name ?? ''} ${new Date().toLocaleDateString('sv-SE')}.pdf`);
			}
		}
	});

    if (loading || !auction) {
        return (
			<div style={{ paddingTop: 100, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<div className="loader"></div>
			</div>
        )
    }

	const selectedItems = auction.items.filter(filterSelected);

	const ownedItemsTotal = selectedItems.filter(i => i.owned).reduce((acc, item) => acc + item.net_amount + item.vat_amount, 0);

	const serviceFeeTotal = auction.serviceFeeRows.reduce((acc, item) => acc + item.net_amount + item.vat_amount, 0);

	const numItems = selectedItems.length;
	const commisionTotal = caluclateCommissionAmount(selectedItems.filter(i => !i.owned).map(i => {
		return {
			customAmount: i.net_amount,
			category: i.object?.category
		}
	}), commissionPercentage / 100);
    
    return (
        <Style>

			<Card size="medium" className="hide-print">
				<div className="header">
					<div className="title">
						<h1>Redovisningsalternativ</h1>
						<h4>{auction.name}</h4>
					</div>
						
					<div className="spacer auto"></div>
				</div>

				<div className="spacer m"></div>

				<div className="column">
					<label htmlFor="commission">Provision (%)</label>
					<div className="spacer xs"></div>
					<input style={{ width: 120 }} type="number" value={commissionPercentage ?? ''} min={0} max={100} onChange={(e) => setCommissionPercentage(Number(e.target.value ?? 0))} name="commission" placeholder="Provision (%)" />
				</div>

				<div className="spacer m"></div>

				<div className="column">
					<Checkbox
						label="Visa endast hämtade objekt"
						checked={onlyDelivered}
						onChange={(e: any) => {
							setOnlyDelivered(e.target.checked)
						}}
					/>
				</div>

				<div className="spacer m"></div>

				<div className="column">
					<Checkbox
						label="Visa endast betalda objekt"
						checked={onlyPaid}
						onChange={(e: any) => {
							setOnlyPaid(e.target.checked)
						}}
					/>
				</div>

				<div className="spacer m"></div>

				<div className="column">
					<Checkbox
						label="Visa endast ej redovisade objekt"
						checked={onlyNonAccounted}
						onChange={(e: any) => {
							setOnlyNonAccounted(e.target.checked)
						}}
					/>
				</div>
			</Card>

			<Card size="small" className={`stats-card hide-print`}>
				<h3>Rader att redovisa</h3>
				<h1>{numItems} st</h1>
				<div className="spacer m"></div>
				<h3>Provision (inkl. moms)</h3>
				<h1>{formatPrice(Math.round(1.25 * commisionTotal * 100) / 100)}</h1>
				<div className="spacer m"></div>
				<h3>Serviceavgift (inkl. moms)</h3>
				<h1>{formatPrice(Math.round(serviceFeeTotal * 100) / 100)}</h1>
				<div className="spacer m"></div>
				<h3>Övrigt (inkl. moms)</h3>
				<h1>{formatPrice(Math.round(ownedItemsTotal * 100) / 100)}</h1>
				<div className="spacer m"></div>
				<Button className="outlined" onClick={() => {
					window.print();
				}}>
					<Icon>print</Icon>Skriv ut
				</Button>

				<div className="spacer s"></div>

				<Button onClick={() => {
					open(<DiscloseItems 
						auction={auction} 
						itemIds={[...selectedIDs, ...auction.serviceFeeRows.map(i => i.id)]} 
						commissionPercentage={commissionPercentage}
						commisionTotal={commisionTotal}
						onComplete={() => {
							handleSavePDF();
							load();
						}}
					/>)
				}}>Redovisa</Button>
			</Card>


            <Card size="full" className="hide-print">
				<div className="header">
					<div className="title">
						<h1>Redovisningsbara objekt</h1>
						<h4>{auction.name}</h4>
					</div>
						
					<div className="spacer auto"></div>
				</div>

				<div className="spacer m"></div>

				<div className="row">
					<Button 
						onClick={() => {
							selectVisible()
						}}
						disabled={auction.items.filter(filter).filter(p => p.object_id != null).length === selectedIDs.length}
					>Välj synliga</Button>

					<div className="spacer ws"></div>

					<Button 
						onClick={() => {
							setSelectedIDs([])
						}}
						disabled={selectedIDs.length === 0}
					>Avmarkera alla</Button>


				</div>


				<Table 
					limit={20}
					data={auction.items.filter(filter)}
					structure={[
						{
							heading: '',
							key: 'id',
							component: (item: AccountableAuctionItem) => {
								return (
									<Checkbox
										checked={selectedIDs.includes(item.id)}
										onChange={(e: any) => {
											if (e.target.checked) {
												setSelectedIDs([...selectedIDs, item.id])
											} else {
												setSelectedIDs(selectedIDs.filter(id => id !== item.id))
											}
										}}
									/>
								)
							}
						},
						{
							heading: 'ID',
							key: 'readable_id',
							component: (item: AccountableAuctionItem) => {
								return <div>
									{item.readable_id} ({item.id})
								</div>
							}
						},
						{
							heading: 'Name',
							key: 'name',
							className: 'name',
							component: (item: AccountableAuctionItem) => {
								if (item.object_id === null) {
									return item.name
								}
								return <Link to={`/objects/${item.object_id}`}>{item.name}</Link>
							}
						},
						{
							heading: 'Faktura / Kvitto',
							key: 'invoice_id',
							component: (item: AccountableAuctionItem) => {
								return item.invoice_id ? <Link to={`/invoices/${item.invoice_id}`}>{item.invoice_id}</Link> : 'Ingen';
							}
						},
						{
							heading: 'Netto',
							key: 'net_amount',
							component: (item: AccountableAuctionItem) => {
								return formatPrice(item.net_amount);
							}
						},
						{
							heading: 'Moms',
							key: 'vat_amount',
							component: (item: AccountableAuctionItem) => {
								return formatPrice(item.vat_amount);
							}
						},
						{
							heading: 'Betalad',
							key: 'paid',
							component: (item: AccountableAuctionItem) => {
								return item.invoice_status === InvoiceStatus.Paid ? <div className="badge green">Betald</div> : <div className="badge red">Ej betald</div>;
							}
						},
						{
							heading: 'Levererat',
							key: 'delivered',
							component: (item: AccountableAuctionItem) => {
								return item.delivered ? <div className="badge green">Hämtat</div> : <div className="badge red">Ej hämtat</div>;
							}
						},
						{
							heading: 'Redovisat',
							key: 'accounted',
							component: (item: AccountableAuctionItem) => {
								return item.accounted ? <div className="badge green">Redovisat</div> : <div className="badge red">Ej redovisat</div>;
							}
						},
						{
							heading: 'Provision',
							key: 'commission',
							component: (item: AccountableAuctionItem) => {

								if (item.owned) {
									return '-';
								}

								return formatPrice(Math.round(caluclateCommissionAmount([
									{
										customAmount: item.net_amount,
										category: item.object?.category
									}
								], commissionPercentage / 100) * 100) / 100);
							}
						},
					]}
				/>


            </Card>

			<Card size="full" className="printable hide-screen">
				<div ref={printable} className="printable--accounting-summary">
					<div className="header">
						<div className="logo">
							<img src="/ra-logga-invoice.png" alt="" />
						</div>

						<div className="document-info">
							<p>
								<b>Auktion:</b> {auction.name} ({auction.id})
							</p>
						</div>
					</div>

					<div className="spacer l"></div>

					<div className="summary-text">
						<p className="small-title metropolis">sålda objekt</p>
						<div className="spacer xs"></div>
						<h1 className="title">Auktionsredovisning</h1>
						<div className="spacer s"></div>
						<p className="description">Tack för du väljer att sälja med oss på Riksauktioner. Här kommer din auktionssammanställning med information om dina sålda objekt och utbetalning. Har du några frågor är du varmt välkommen att kontakta oss på: ekonomi@riksauktioner.se</p>
					</div>

					<div className="spacer l"></div>

					<div className="summary-row">
						<div className="column summary">
							<p className="small-title">summa total försäljning</p>
							<div className="spacer xs"></div>
							<div className="box">
								<p>
									Summa: <b>{formatPrice(selectedItems.reduce((acc, item) => acc + item.net_amount, 0))}</b>
								</p>
								<p>
									Moms: <b>{formatPrice(selectedItems.reduce((acc, item) => acc + item.vat_amount, 0))}</b>
								</p>
								<p>
									Totalt ink. moms: <b>{formatPrice(selectedItems.reduce((acc, item) => acc + item.net_amount + item.vat_amount, 0))}</b>
								</p>
							</div>
						</div>

						<div className="column commission">
							<p className="small-title">provision</p>
							<div className="spacer xs"></div>
							<div className="box">
								<p>
									Provision: <b>{formatPrice(commisionTotal.toFixed(2))}</b> ({commissionPercentage}%)
								</p>
								<p>
									Moms: <b>{formatPrice((commisionTotal * 0.25).toFixed(2))}</b>
								</p>
								<p>
									Totalt ink. moms: <b>{formatPrice(commisionTotal * 1.25)}</b>
								</p>
							</div>
						</div>
					</div>

					<div className="spacer m"></div>

					<div className="to-be-recived-box">
						<p className="small-title">insättning</p>
						<div className="spacer s"></div>
						<p className="italics">
							Insättning sker <b>14</b> bankdagar efter avslutad auktion.
						</p>
						<div className="spacer s"></div>
						<p>
							Summa: <b>{formatPrice(selectedItems.reduce((acc, item) => acc + item.net_amount + item.vat_amount, 0) - commisionTotal * 1.25)}</b>
						</p>
						{/* {seller.bank_account_number && (
							<p>
								Insättning sker till konto: <b>{seller.bank_account_number}</b>
							</p>
						)} */}
					</div>

					<div className="spacer m"></div>

					<p className="small-title">dina sålda objekt</p>
					<div className="spacer xs"></div>
					<table className="objects-table" cellSpacing={0}>
						<thead>
							<tr>
								<th></th>
								<th></th>
								<th>Betaldatum</th>
								<th>Vinnande bud</th>
							</tr>
						</thead>

						<tfoot>
							<tr>
								<td style={{ height: '2rem' }}></td>
							</tr>
						</tfoot>
						<tbody>
							{selectedItems.map((item) => (
								<tr key={item.id}>
									<td className="image">
										{item.object && <img src={item.object.thumbnail_url ?? ""} alt="" />}
									</td>
									<td className="title">
										<div className="id">
											ID: {item.readable_id}
										</div>
										<div className="title">
											<b>{item.name}</b>
										</div>
									</td>
									<td className="ended">
										{item.invoice_paid_date ? new Date(item.invoice_paid_date).toLocaleDateString('sv-SE') : '-'}
									</td>
									<td className="bid">
										<b>{formatPrice(item.net_amount)}</b>
									</td>
								</tr>
							))}
						</tbody>
					</table>

					<div className="footer">
						<div className="meta-row">
							<p>Riksauktioner AB</p>
							<p>Org. 556236-6954</p>
							<p>Tel: 08 446 17 12</p>
							<p>BG: 5849-4808</p>
							<p>www.riksauktioner.se</p>
						</div>
					</div>
				</div>
			</Card>
        </Style>
    )
}

const Style = styled(Page)`
	.key-values {
		h1 {
			font-size: 2.5em;
		}
	}

	.stats-card {
		h1 {
			margin-top: 0.5rem;
			font-size: 2.5rem;
		}
	}

	.filters {
		border-radius: 0.5rem;
		display: flex;
		flex-wrap: wrap;

		.checkbox-container {
			font-size: 1em;
		}
	}

	.hide-print .header {
		display: flex;
		justify-content: flex-end;
		align-items: center;

		form {
			display: flex;
			align-items: center;
		}
	}

	.hide-print table {
		td.name a {
			max-width: 400px;
			display: inline-block;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.hide-print .row-icons {
		div {
			display: flex;
			justify-content: flex-end;
			font-size: 1.5em;

			.icon {
				margin-left: 0.5em;
			}
		}
	}
`;